// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id:"previsit",
        category:"professional",
        image:"previsit-banner.png",
        panelSubheading:"OPTUM | UNITEDHEALTHGROUP",
        heading: "Pre-visit Assessment",
        subheading:"Web native application for members to complete pre-visit assessment before Advance Care Practitioner makes house visits",
        badges: ["Figma", "Variables", "Design System", "User Research"],
        secure:false,
    },
    {
        id:"optum",
        category:"professional",
        image:"optum-banner.png",
        panelSubheading:"OPTUM | UNITEDHEALTHGROUP",
        heading: "Care Solutions - House Calls",
        subheading:"Interface for Advance Care Practitioner to scribe vitals during house visits on a hand held device",
        badges: ["Handheld Device", "Healthcare", "Design System", "Task flows"],
        secure:false,
    },
    {
        id:"smartsales",
        category:"professional",
        image:"opportunity-banner.png",
        panelSubheading:"TATA CONSULTANCY SERVICES",
        heading: "SmartSales Opportunity CRM",
        subheading:"Curated platform for sales users to execute, track & manage large value deals, contracts & IT offerings.",
        badges: ["Enterprise", "B2B", "Data-viz", "Task flows"],
        secure:false,
    },
    {
        id:"paceport",
        category:"professional",
        image:"pace-port-banner.png",
        panelSubheading:"TATA CONSULTANCY SERVICES",
        heading: "Pace Port Digital Library",
        subheading:"Showcasing TCS intellectual property at various Pace Ports across the world for clients and visitors",
        badges: ["Digital Transformation", "Branding", "Information Architecture"],
        secure:false,
    },
    {
        id:"marysplace",
        category:"academic",
        image:"marys-place-banner.png",
        panelSubheading:"UNIVERSITY OF WASHINGTON",
        heading: "Mary's Place and Starbucks",
        subheading:"A mobile application to aid Mary's Place field staff while assisting families experiencing homelessness",
        badges: ["NGO", "User research", "Usability testing", "Design thinking"],
        secure:false,
    },
    {
        id:"vocalamp",
        category:"academic",
        image:"vocalamp-banner.png",
        panelSubheading:"UNIVERSITY OF WASHINGTON",
        heading: "Vocalamp - IoT",
        subheading:"Smart Lamp for enhancing vocabulary and pronunciation for children and engagement with parents",
        badges: ["IoT", "HCI", "Literature", "Hardware-Softare Prototype"],
        secure:false,
    },
    {
        id:"whackamole",
        category:"academic",
        image:"whacka-mole-banner.png",
        panelSubheading:"UNIVERSITY OF WASHINGTON",
        heading: "Whack-a-mole - Game Design",
        subheading:"A gamified Approach to Cognitive Assessment with Machine Learning based predictions",
        badges: ["IoT", "HCI", "IEEE GHTC '22", "Hardware-Softare Prototype"],
        secure:false,
    },
]

