import React from "react";

const InfoCard = ({ label, items = [] }) => {
  // Only render if there's at least one non-empty item
  if (!items.some(item => item)) return null;

  return (
    <div className="flex flex-col items-start w-full mt-8 p-6 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
      <p className="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-400 leading-relaxed">
        {label}
      </p>
      {items.map((text, index) => (
        text && (
          <li
            key={index}
            className="md:text-base text-gray-600 dark:text-gray-400 leading-relaxed"
          >
            {text}
          </li>
        )
      ))}
    </div>
  );
};

const ProjectHeroPanel = ({
  image,
  text1 = "",
  text2 = "",
  text3 = "",
  text4 = "",
  text5 = "",
  text6 = "",
  text7 = "",
  text8 = "",
  text9 = ""
}) => {
  const sections = [
    {
      label: "Key contributions",
      items: [text1, text2, text3]
    },
    {
      label: "Team",
      items: [text4, text5, text6]
    },
    {
      label: "Duration",
      items: [text7, text8, text9]
    }
  ];

  return (
    <div className="block relative mt-12 mb-36">
      <div className="container grid">
        {image && (
          <div className="flex flex-col items-start w-full relative rounded-xl overflow-hidden bg-stone-300/80 dark:bg-gray-800">
            <img 
              src={require(`../Images/projectHeroPanel/${image}`)} 
              alt="" 
              className="rounded-2xl w-full h-auto transform hover:scale-105 transition-transform duration-300"
            />
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {sections.map((section, index) => (
            <InfoCard
              key={index}
              label={section.label}
              items={section.items}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectHeroPanel;