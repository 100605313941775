const Dev = () => {
    return(
        <div className="block relative mt-12 sm:mt-24 md:mt-36 mb-12 sm:mb-24 md:mb-36">
        <div className="container mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100 leading-tight my-4 sm:my-5 md:my-6">
                Heading 1
            </h1>
    
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-4 sm:my-4 md:my-5">
                Heading 2
            </h2>
    
            <h3 className="text-xl sm:text-2xl md:text-3xl font-medium text-gray-700 dark:text-gray-300 leading-normal my-3 sm:my-3 md:my-4">
                Heading 3
            </h3>
    
            <h4 className="text-lg sm:text-xl md:text-2xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3 sm:my-3 md:my-4">
                Heading 4
            </h4>
    
            <h5 className="text-base sm:text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-2 sm:my-2 md:my-3">
                Heading 5
            </h5>
    
            <h6 className="text-base sm:text-lg md:text-base font-medium text-gray-500 dark:text-gray-300 leading-snug my-1 sm:my-1 md:my-2">
                Heading 6
            </h6>
    
            <p className="text-base sm:text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed my-3 sm:my-4 md:my-4">
                This is a paragraph. It's styled for optimal readability with a larger font size and comfortable line height.
            </p>
    
            <a href="#" className="text-indigo-600 dark:text-indigo-400 hover:underline font-medium inline-block">
                This is a link
            </a>
    
            <ul className="list-disc pl-4 sm:pl-5 md:pl-6 my-3 sm:my-4 md:my-4 text-base text-gray-600 dark:text-gray-400">
                <li className="mb-2">First item in list</li>
                <li className="mb-2">Second item in list</li>
            </ul>
    
            <ol className="list-decimal pl-4 sm:pl-5 md:pl-6 my-3 sm:my-4 md:my-4 text-base text-gray-600 dark:text-gray-400">
                <li className="mb-2">First ordered item</li>
                <li className="mb-2">Second ordered item</li>
            </ol>
    
            <hr className="border-slate-200 dark:border-slate-800 my-6" />
    
            <h6 className="font-mono text-base sm:text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500 dark:text-indigo-400">
                MOVIES
            </h6>
    
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight mb-2 text-gray-900 dark:text-gray-100 leading-tight">
                Dune: The Messiaha
            </h1>
    
            <h4 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 text-gray-600 dark:text-gray-400">
                Arrakis
            </h4>
    
            <p className="text-base sm:text-lg md:text-base mb-6 sm:mb-7 md:mb-8 text-gray-600 dark:text-gray-400">
                A beginning is the time for taking the most delicate care that the balances are correct.
                This every sister of the Bene Gesserit knows. To begin your study of the life of Muad'Dib,
                then, take care that you first place him in his time: born in the 57th year of the Padishah
                Emperor, Shaddam IV. And take the most special care that you locate Muad'Dib in his place:
                the planet Arrakis. Do not be deceived by the fact that he was born on Caladan and lived
                his first fifteen years there. Arrakis, the planet known as Dune, is forever his place.
            </p>
    
            <hr className="mb-6 sm:mb-7 md:mb-8 border-slate-200 dark:border-slate-800" />
    
            <h6 className="font-mono text-base sm:text-lg md:text-base font-semibold mb-2 tracking-widest text-indigo-500 dark:text-indigo-400">
                MOVIES
            </h6>
    
            <h3 className="text-2xl sm:text-3xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200">
                Dune: The Messiaha
            </h3>
    
            <h4 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 text-gray-600 dark:text-gray-400">
                Arrakis
            </h4>
    
            <p className="text-base sm:text-lg md:text-base mb-6 sm:mb-7 md:mb-8 text-gray-600 dark:text-gray-400">
                A beginning is the time for taking the most delicate care that the balances are correct.
                This every sister of the Bene Gesserit knows. To begin your study of the life of Muad'Dib,
                then, take care that you first place him in his time: born in the 57th year of the Padishah
                Emperor, Shaddam IV. And take the most special care that you locate Muad'Dib in his place:
                the planet Arrakis. Do not be deceived by the fact that he was born on Caladan and lived
                his first fifteen years there. Arrakis, the planet known as Dune, is forever his place.
            </p>
    
            <hr className="mb-6 sm:mb-7 md:mb-8 border-slate-200 dark:border-slate-800" />
        </div>
    </div>
    )
}

export default Dev;