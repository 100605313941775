import React, { useRef } from 'react';
import { motion, px, useScroll, useTransform } from 'framer-motion';

const ImageHorizontalScroll = ({ image, scale = 1 }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    // Define motion values for transformations
    const x = useTransform(scrollYProgress, [0, 1], ["0%", `-${image.length * 100 -100}%`]);
    const borderRadius = "0.75rem";
    return (
        <div className="relative h-[500vh]" ref={targetRef}>
        <div className="sticky h-[80vh] top-8 flex items-center justify-start overflow-hidden">
            <motion.div className="flex gap-4 w-[500vw]">  {/* Adjust 400vw based on number of images */}
                {image.map((img, index) => {
                    return (
                        <motion.div 
                            key={index}
                            className="w-screen h-auto " // This gives each image container full viewport width
                        >
                            <motion.img
                                translate='yes'
                                src={img.src}
                                alt={`Image ${index}`}
                                className="w-full h-auto object-contain"
                                style={{
                                    x,
                                    scale,
                                    borderRadius,
                                }}
                            />
                        </motion.div>
                    );
                })}
            </motion.div>
        </div>
    </div>
    );
};

export default ImageHorizontalScroll;